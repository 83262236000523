function CheckNumeric(e) {
  if(e){
    if(window.event){
        if ((e.keyCode <48 || e.keyCode > 57) && e.keyCode != 44) {
            event.returnValue = false;
            return false;
        }
    } else{ // Fire Fox
        if ((e?.which <48 || e?.which > 57) && e?.which != 44) {
            e.preventDefault();
            return false;
        }
    }
  }
}

function CheckDateInput(e) {
  if(e){
    if(window.event){
      if ((e.keyCode <48 || e.keyCode > 57) && e.keyCode != 46) {
          event.returnValue = false;
          return false;
      }
    } else{ // Fire Fox
        if ((e?.which <48 || e?.which > 57) && e?.which != 46) {
            e.preventDefault();
            return false;
        }
    }
  }
}

function IsCharDate(e) {
  if(e){
    if(window.event){
        if ((e.keyCode <48 || e.keyCode > 57) && e.keyCode != 46) {
            event.returnValue = false;
            return false;
        }
    } else{ // Fire Fox
        if ((e?.which <48 || e?.which > 57) && e?.which != 46) {
            e.preventDefault();
            return false;
        }
    }
  }
}

function OpenPdfNewTab(data) {
  const blob = new Blob([data], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.click();
}
